import Service from '@/shared/classes/service'
import { getNodeApiUrlByVersion } from "@/config";

export default class CoursesService extends Service {
  static prefix = 'courses'

  static destroy(id: string): Promise<any> {
    return this.delete(`${getNodeApiUrlByVersion()}/${ this.prefix }/${ id }`)
  }

  static show(id: string): Promise<any> {
    return this.get(`${getNodeApiUrlByVersion()}/${ this.prefix }/${ id }`)
  }
}
