

















import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import { AdminRoutes } from '@/shared/router/admin'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import { getNodeApiUrlByVersion } from "@/config";
import Course from "@/shared/modules/courses/course.model";
import CoursesService from "@/shared/modules/courses/courses.service";
import DataTableFilter, { FilterOperators } from "@/shared/classes/components/data-table/data-table-filter";
import SelectField from "@/shared/classes/form/fields/Select/select-field";
import SelectItem from "@/shared/classes/form/fields/Select/select-item";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import Model from "@/shared/classes/model";
import IModelResponse from "@/shared/interfaces/modules/model-response.interface";
import router from "@/shared/router";

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class CoursesIndex extends Vue {
  table: DataTableBase | null = null

  created() {
    this.table = new DataTableBase()
      .setModel(Course)
      .setEndpoint(`${getNodeApiUrlByVersion()}/courses`)
      .setGroupBy('courseCategoryCreatedAt', 'courseCategoryName')
      .setGroupDesc(false)
      .setHeaders([
        new DataTableHeader()
          .setKey('name')
          .setSortable(false)
          .setText(__('admin.views.courses.index.table.columns.name')),
        new DataTableHeader()
          .setKey('isVisible')
          .setSortable(false)
          .setValueParser((value: any, item: Course) => {
            if (item.isVisible) {
              return __('admin.views.courses.index.table.columns.visible-option')
            }

            return __('admin.views.courses.index.table.columns.invisible-option')
          })
          .setText(__('admin.views.courses.index.table.columns.is-visible')),
      ])
      .setActions([
        new EditAction()
          .setAction((item: Model<IModelResponse>) => router.push({ path: `${ router.currentRoute.path }/${item.uuid}/overview`})),
        new DeleteAction()
          .setAction((item: Course) => this.$store.dispatch(GlobalActions.showDialog, {
            show: true,
            component: AreYouSureDialog,
            meta: {
              title: __('admin.views.courses.index.table.actions.delete.title'),
              text: __('admin.views.courses.index.table.actions.delete.text', { name: item.name }),
              onYes: () => CoursesService.destroy(item.uuid)
                .then(() => {
                  this.tableRef.refresh()
                  this.$store.dispatch(GlobalActions.showSnackBar, {
                    type: SnackBarTypes.success,
                    message: __('admin.views.courses.index.table.actions.delete.on-success'),
                  })
                })
            }
          }))
      ])
      .setFilters([
        new DataTableFilter()
          .setOperator(FilterOperators.equals)
          .setKeyIsPrimary()
          .setField(
            new SearchableField()
              .setKey('courseCategoryUuid')
              .setTitle(__('admin.views.courses.index.table.filters.category'))
              .loadItems({
                endpoint: `${getNodeApiUrlByVersion()}/course-categories`, value: 'uuid', title: 'name', perPage: 20
              }),
          ),
        new DataTableFilter()
          .setKeyIsPrimary()
          .setField(
            new SelectField()
              .setKey('isVisible')
              .setItems([
                new SelectItem()
                  .setValue(true)
                  .setTitle(__("admin.views.courses.index.table.columns.visible-option")),
                new SelectItem()
                  .setValue(false)
                  .setTitle(__("admin.views.courses.index.table.columns.invisible-option")),
              ])
              .setTitle(__('admin.views.courses.index.table.filters.is-visible'))
          ),
      ])
  }

  navigateToCreate(): void {
    this.$router.push({ name: AdminRoutes.coursesCreate })
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
