import { render, staticRenderFns } from "./CoursesIndex.vue?vue&type=template&id=aaa1dfcc&"
import script from "./CoursesIndex.vue?vue&type=script&lang=ts&"
export * from "./CoursesIndex.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContent } from 'vuetify/lib/components/VContent';
installComponents(component, {VAlert,VBtn,VCard,VContent})
